<template>
  <div class="activityMgmt-addOrEditor">

    <div>
      <div class="container">
        <el-form :model="info"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="150px">
          <div class="lineH-box">
            <div class="lineH"></div>
            <div>基本信息</div>
          </div>
          <el-form-item label="活动名称"
                        prop="act_name">
            <el-input v-model="info.act_name"
                      maxlength="10"
                      style="width: 280px"
                      placeholder="不超过10个字"></el-input>
          </el-form-item>

          <el-form-item label="是否显示入口"
                        prop="is_show">
            <el-radio v-model="info.is_show"
                      :label="1">是</el-radio>
            <el-radio v-model="info.is_show"
                      :label="0">否</el-radio>
            <span style="color: #999; font-size: 12px; margin-left: 5px">
              选择否则主页活动区域无此活动入口，可通过配置首页banner等外链配置该入口
            </span>
          </el-form-item>

          <el-form-item label="活动时间"
                        prop="act_time">
            <el-date-picker v-model="info.act_time"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy-MM-dd HH:mm"
                            :disabled="act_id"></el-date-picker>
          </el-form-item>

          <el-form-item label="限购"
                        prop="limit_buy">
            <el-radio v-model="info.limit_buy"
                      :label="0">不限购</el-radio>
            <el-radio v-model="info.limit_buy"
                      :label="1">
              每人每spu限购
              <el-input-number v-model="info.limit_buy_num"
                               step-strictly
                               :step="1"
                               :min="1"
                               :max="1000000000"
                               :controls="false"
                               placeholder="请输入数字"
                               style="width: 110px; margin: 0 5px"></el-input-number>件
            </el-radio>
          </el-form-item>
          <el-form-item label="活动分享" prop="is_share">
            <el-radio v-model="info.is_share" :label="1">可分享</el-radio>
            <el-radio v-model="info.is_share" :label="0">不支持分享</el-radio>
          </el-form-item>
          <el-form-item label="活动入口图"
                        prop="enter_img">
            <el-upload name="image"
                       :show-file-list="false"
                       :http-request="uploadImg"
                       action="enter_img"
                       accept=".png,.jpeg,.jpg">
              <el-button slot="trigger"
                         type="text">上传本地图片</el-button>
              <span style="color: #999; font-size: 12px; margin-left: 10px">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
            </el-upload>
          </el-form-item>
          <div class="boxown"
               v-show="info.enter_img">
            <img :src="info.enter_img_str"
                 class="img" />
            <el-upload :show-file-list="false"
                       :http-request="uploadImg"
                       action="enter_img"
                       accept=".png,.jpeg,.jpg">
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search"
                     @click.stop="showImg(info.enter_img_str)"></i>
                  <i class="el-icon-delete"
                     @click.stop="delImg(1)"></i>
                </div>
              </div>
            </el-upload>
          </div>

          <el-form-item label="活动页banner"
                        prop="banner_img">
            <el-upload name="image"
                       :show-file-list="false"
                       :http-request="uploadImg"
                       action="banner_img"
                       accept=".png,.jpeg,.jpg">
              <el-button slot="trigger"
                         type="text">上传本地图片</el-button>

              <span style="color: #999; font-size: 12px; margin-left: 10px">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
            </el-upload>
          </el-form-item>
          <div class="boxown"
               v-show="info.banner_img">
            <img :src="info.banner_img_str"
                 class="img" />
            <el-upload :show-file-list="false"
                       :http-request="uploadImg"
                       action="banner_img"
                       accept=".png,.jpeg,.jpg">
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search"
                     @click.stop="showImg(info.banner_img_str)"></i>

                  <i class="el-icon-delete"
                     @click.stop="delImg(2)"></i>
                </div>
              </div>
            </el-upload>
          </div>
  <!-- 分享海报图 -->
          <el-form-item label="分享海报图"
                        prop="share_poster">
            <el-upload name="image"
                       :show-file-list="false"
                       :http-request="uploadImg"
                       action="share_poster"
                       accept=".png,.jpeg,.jpg">
              <el-button slot="trigger"
                         type="text">上传本地图片</el-button>

              <span style="color: #999; font-size: 12px; margin-left: 10px">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
            </el-upload>
          </el-form-item>
          <div class="boxown"
               v-show="info.share_poster">
            <img :src="info.share_poster_str"
                 class="img" />
            <el-upload :show-file-list="false"
                       :http-request="uploadImg"
                       action="share_poster"
                       accept=".png,.jpeg,.jpg">
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search"
                     @click.stop="showImg(info.share_poster_str)"></i>

                  <i class="el-icon-delete"
                     @click.stop="delImg(3)"></i>
                </div>
              </div>
            </el-upload>
          </div>

  <!-- 分享卡片图 -->

          <el-form-item label="分享卡片图"
                        prop="share_img">
            <el-upload name="image"
                       :show-file-list="false"
                       :http-request="uploadImg"
                       action="share_img"
                       accept=".png,.jpeg,.jpg">
              <el-button slot="trigger"
                         type="text">上传本地图片</el-button>

              <span style="color: #999; font-size: 12px; margin-left: 10px">大小在5MB以内，支持png,jpeg,gif格式，最多可上传1张</span>
            </el-upload>
          </el-form-item>
          <div class="boxown"
               v-show="info.share_img">
            <img :src="info.share_img_str"
                 class="img" />
            <el-upload :show-file-list="false"
                       :http-request="uploadImg"
                       action="share_img"
                       accept=".png,.jpeg,.jpg">
              <div class="shadow column-center column">
                <div>
                  <i class="el-icon-search"
                     @click.stop="showImg(info.share_img_str)"></i>

                  <i class="el-icon-delete"
                     @click.stop="delImg(4)"></i>
                </div>
              </div>
            </el-upload>
          </div>

          <el-form-item label="标签显示">
            <el-input v-model="info.laber"
                      maxlength="4"
                      style="width: 280px"
                      placeholder="不超过4个字"></el-input>
            <span style="color: #999; font-size: 12px; margin-left: 10px">用于用户端商品标签显示</span>
          </el-form-item>

          <el-form-item label="是否显示优惠券入口"
                        prop="is_show_coupon">
            <el-radio v-model="info.is_show_coupon"
                      :label="1">是</el-radio>
            <el-radio v-model="info.is_show_coupon"
                      :label="0">否</el-radio>
            <span style="color: #999; font-size: 12px; margin-left: 5px">
              用户可以在APP端点击入口领取以下商品列表可使用的所有优惠券
            </span>
          </el-form-item>

          <div class="title flex between">
            <span>
              <div class="flex">
                <div class="lineH-box">
                <div class="lineH"></div>
                <div>商品列表</div>
              </div>
              <!-- <span style="color: #e0372d">*</span>
                商品列表 -->
                <div>
                <el-button style="margin-left: 10px"
                          type="primary"
                          @click="chooseGoodShow = true">选择商品</el-button>
                </div>
              </div>
            </span>
            <span>
              <el-button type="primary"
                         style="width: 120px"
                         @click="submitForm">保存</el-button>
              <el-button style="margin-left: 10px; width: 120px"
                         @click="back">取消</el-button>
            </span>
          </div>
          <div style="border: solid 1px #c9cbd6;width:100%;">
            <el-table ref="multipleTable"
                      :data="info.goods_list"
                      tooltip-effect="dark"
                      style="width: 100%; min-width: 1000px"
                      :header-cell-style="{ background: '#F5F5F5' }">
              <!-- <el-table-column label="排序" min-width="140">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.sort"
                                   style="width: 120px"
                                   :controls="false"
                                   :precision="0"
                                   :min="0"
                                   :max="9999999999"
                                   maxlength="10"
                                   @change="(currentValue, oldValue) => {return changeSort(currentValue,oldValue,scope.row,scope.$index);}">
                  </el-input-number>
                </template>
              </el-table-column> -->
              <el-table-column label="商品"
                               width="250">
                <template slot-scope="scope">
                  <div class="flex between"
                       style="width: 300px">

                    <img v-if="scope.row.picture ==''"
                         :src="defaultImg"
                         style="width: 76px; height: 76px">
                    <el-image v-else
                              :src="scope.row.picture_str"
                              style="width: 76px; height: 76px" />

                    <div style="width: 215px">{{ scope.row.goods_name }}</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="sku_name"
                               label="规格" />
              <el-table-column prop="price"
                               label="价格" />
              <el-table-column prop="member_price"
                               label="会员价">
              <template slot-scope="scope">
                {{scope.row.member_price==='0.00'?'':scope.row.member_price}}
              </template>
              </el-table-column>
              <el-table-column prop="stock"
                               label="库存" />
              <el-table-column label="活动价" min-width="140">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.promote_price"
                                   :min="0"
                                   style="width: 120px"
                                   :controls="false"
                                   :max="9999999.99"
                                   :precision="2"
                                   placeholder="请输入数字"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="活动限售量" min-width="140">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.limit_sale"
                                   style="width: 120px"
                                   :min="0"
                                   :controls="false"
                                   :precision="0"
                                   :max="99999"
                                   placeholder="请输入数字"></el-input-number>
                </template>
              </el-table-column>
              <el-table-column label="置顶图片" min-width="120px">
                <template slot-scope="scope">
                  <div class="flex between">
                    <el-image v-if="scope.row.poster_img_str"
                              :src="scope.row.poster_img_str"
                              style="width: 76px; height: 76px"
                              @click="showImg(scope.row.poster_img_str)"/>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" min-width="140">
                <template slot-scope="scope">
                  <div class="flex">
                    <el-button type="text"
                               @click="delGoods(scope.$index)">删除</el-button>

                    <el-upload name="image"
                               accept=".png,.jpeg,.jpg"
                               :show-file-list="false"
                               v-show="!scope.row.is_top"
                               :http-request="(params) => {uploadImg(params, scope.row);}"
                               action="goods_list">
                      <el-popover placement="top"
                                  trigger="hover"
                                  content="设为置顶广告时，需上传广告图片">
                        <el-button style="margin-left: 10px"
                                   type="text"
                                   slot="reference">设为置顶广告</el-button>
                      </el-popover>
                    </el-upload>
                    <el-button type="text"
                               v-show="scope.row.is_top"
                               style="margin-left: 10px"
                               @click="cancelTop(scope.row)">取消置顶广告</el-button>
                    <!-- <el-button type="text"
                               v-show="scope.row.poster_img"
                               @click="showImg(scope.row.poster_img_str)">mypic.jpg</el-button> -->
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 图片预览 -->
    <el-image-viewer v-if="showViewer"
                     :on-close="() => {showViewer = false;}"
                     :url-list="imgList" />
    <!-- 选择活动商品 -->
    <ChooseGood :act_id="info.act_id || 0"
                v-if="chooseGoodShow"
                @close="chooseGoodShow = false"
                @checkGoods="checkGoods"></ChooseGood>
  </div>
</template>
<script>
import commonUtil from '../../../utils/commonUtil.js';
import dayjs from 'dayjs';
import operation from '@/api/operation';
import { uploadImg, getImgUrl } from '@/service/common';
import ChooseGood from './ChooseGood';
export default {
  data() {
    return {
      page_title: '新增活动',
      act_id: false,
      info: {
        act_name: '',
        is_show: 1,
        act_time: [],
        limit_buy: 0,
        limit_buy_num: '',
        banner_img: '',
        banner_img_str: '',
        enter_img: '',
        enter_img_str: '',
        share_img: '',
        share_img_str: '',
        share_poster: '',
        share_poster_str: '',
        laber: '',
        is_show_coupon: 0,
        goods_list: [],
        is_share: 1,
      },
      rules: {
        act_name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        act_time: [
          { required: true, message: '请选择活动时间', trigger: 'blur' }
        ],
        is_show: [{ required: true, message: '是否显示入口', trigger: 'blur' }],
        limit_buy: [
          { required: true, message: '请选择是否限购', trigger: 'blur' }
        ],
        goods_list: [
          { required: true, message: '请选择置顶商品', trigger: 'blur' }
        ],
        enter_img: [{ required: true, message: '请上传活动入口图' }],
        banner_img: [{ required: true, message: '请上传活动页banner' }],
        share_img: [{ required: true, message: '请上传分享卡片图' }],
        share_poster: [{ required: true, message: '请上传分享海报图' }],
        is_show_coupon: [{ required: true, message: '' }],
        is_share: [{ required: true, message: "请选择商品是否可分享" }],
      },
      imgList: [],
      showViewer: false,
      chooseGoodShow: false
    };
  },
  components: {
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer'),
    ChooseGood
  },
  computed: {
    defaultImg() {
      return require('../../../assets/default_img.png');
    }
  },
  created() {
    if (this.$route.query.act_id) {
      this.page_title = '编辑活动';
      this.act_id = true;
      this.getInfo();
    }
  },
  methods: {
    //获取详情
    getInfo() {
      let act_id = this.$route.query.act_id;
      operation.getActivityInfo({ act_id }).then((res) => {
        let info = res.data.info;
        this.info = info;
        this.info.act_time = [
          dayjs.unix(info.start_time).format('YYYY-MM-DD HH:mm'),
          dayjs.unix(info.end_time).format('YYYY-MM-DD HH:mm')
        ];
        if (info.limit_buy > 0) {
          this.info.limit_buy_num = info.limit_buy;
          this.info.limit_buy = 1;
        }
        this.info.act_id = this.$route.query.act_id;
      });
    },
    //上传图片
    uploadImg(params, item) {
      let file = params.file;
      uploadImg(file)
        .then((res) => {
          if (params.action == 'enter_img') {
            this.info.enter_img = res.data.pic;
            this.info.enter_img_str = res.data.path;
          } else if (params.action == 'banner_img') {
            this.info.banner_img = res.data.pic;
            this.info.banner_img_str = res.data.path;
          } else if (params.action == 'share_img') {
            this.info.share_img = res.data.pic;
            this.info.share_img_str = res.data.path;
          } else if (params.action == 'share_poster') {
            this.info.share_poster = res.data.pic;
            this.info.share_poster_str = res.data.path;
          } else if (params.action == 'goods_list') {
            let index = this.info.goods_list.findIndex((ele) => {
              return ele.goods_id == item.goods_id;
            });
            this.$set(this.info.goods_list[index], 'poster_img', res.data.pic);
            this.$set(
              this.info.goods_list[index],
              'poster_img_str',
              res.data.path
            );
            this.$set(this.info.goods_list[index], 'is_top', 1);
            this.$forceUpdate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //查看图片
    showImg(item) {
      this.imgList = [item];
      this.showViewer = true;
    },
    //删除图片
    delImg(type) {
      this.$confirm('确定删除图片吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          if (type == 1) {
            this.info.enter_img = '';
            this.info.enter_img_str = '';
          } else if (type == 2) {
            this.info.banner_img = '';
            this.info.banner_img_str = '';
          } else if (type == 3){
            this.info.share_poster = '';
            this.info.share_poster_str = '';
          } else if (type == 4){
            this.info.share_img = '';
            this.info.share_img_str = '';
          }
        })
        .catch(() => {});
    },
    //选择商品
    checkGoods(goods) {
      // console.log(goods);
      let list = [];
      goods.map((item) => {
        if (item.open_spec == 1) {
          item.item.map((i) => {
            let data = {
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              picture_str: i.picture_str,
              picture: i.picture,
              sku_name: i.sku_name,
              sku_id: i.sku_id,
              stock: i.stock,
              price: i.price,
              member_price: i.member_price == 0 ? undefined : i.member_price,
              sort: 0,
              promote_price: undefined,
              limit_sale: undefined,
              is_top: 0
            };
            list.push(data);
          });
        } else {
          item.sort = 0;
          item.promote_price = undefined;
          item.limit_sale = undefined;
          (item.member_price =
            item.member_price == 0 ? undefined : item.member_price),
            (item.sku_id = 0);
          item.is_top = 0;
          list.push(item);
        }
      });
      list.forEach((ele1) => {
        let index = this.info.goods_list.findIndex((ele2) => {
          if (ele1.sku_id) {
            return ele2.sku_id == ele1.sku_id;
          } else {
            return ele2.goods_id == ele1.goods_id;
          }
        });
        if (index == -1) {
          this.info.goods_list.push(ele1);
        }
      });
      this.chooseGoodShow = false;
    },
    //取消置顶
    cancelTop(item) {
      this.$confirm('您确定要取消吗？取消后图片同时被删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          item.poster_img = '';
          item.poster_img_str = '';
          item.is_top = 0;
          this.$forceUpdate();
        })
        .catch(() => {});
    },
    //删除商品
    delGoods(index) {
      commonUtil.lostFocus(event)
      this.info.goods_list.splice(index, 1);
    },
    // //排序的修改
    // changeSort(currentValue, oldValue, row, index) {
    //   if (!currentValue && currentValue != 0) {
    //     let data = row;
    //     data.sort = oldValue;
    //     this.info.goods_list.splice(index, 1);
    //     this.$set(this.info.goods_list, index, data);
    //     this.$forceUpdate();
    //   }
    // },
    //提交添加
    submitForm() {
      commonUtil.lostFocus(event)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.info.goods_list.length) {
            this.$message.error('请选择参与活动的商品');
            return false;
          }
          let list = this.info.goods_list;
          for (let index = 0; index < list.length; index++) {
            if (!list[index].promote_price || !list[index].limit_sale) {
              this.$message.error('请完善商品活动信息');
              return false;
            }
          }
          this.info.goods_list.forEach((item) => {
            if (item.poster_img) {
              item.is_top = 1;
            } else {
              item.is_top = 0;
            }
          });
          let params = this.info;
          params.start_time = dayjs(params.act_time[0]).format(
            'YYYY-MM-DD HH:mm'
          );
          params.end_time = dayjs(params.act_time[1]).format(
            'YYYY-MM-DD HH:mm'
          );
          if (params.limit_buy == 1) {
            params.limit_buy = params.limit_buy_num;
          }
          params['act_id'] = this.$route.query.act_id ? params['act_id'] : '';
          // let url = '';
          // if (this.$route.query.act_id) {
          //   url = 'platform/shop/editShopActivity';
          // } else {
          //   url = 'platform/shop/addShopActivity';
          // }
          operation.creatShopActivity(params).then((res) => {
            if (res.code == 0) {
              this.$message.success('操作成功');
              this.back();
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    back() {
      history.back();
    }
  }
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.container {
  box-sizing: border-box;
  background-color: #ffffff;
  min-height: calc(100vh - 140px);
  text-align: left;
}
.title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
}
</style>
<style lang="scss">
.el-image > img {
  height: 100px;
  width: 100px;
  display: block;
}
.el-image-viewer__close {
  i {
    color: #ffffff;
  }
}
.boxown {
  position: relative;
  padding-left: 150px;
  box-sizing: border-box;
  &:hover .shadow {
    display: flex;
  }
  .img {
    width: 100px;
    height: 100px;
  }
  .shadow {
    box-sizing: border-box;
    position: absolute;
    left: 150px;
    top: 0;
    width: 100px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
  }
  i + i {
    margin-left: 30px;
    color: #d44747;
  }
  i {
    color: #ffffff;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
  }
}
</style>


