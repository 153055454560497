<template>
  <el-dialog title="选择商品"
             :visible.sync="dialog"
             width="600px"
             style="text-align:left;"
             :close-on-click-modal="false"
             append-to-body
             class="big"
             :show-close="false">
    <div>
      <div>
        <el-input v-model="goods_name"
                  placeholder="请输入商品"
                  style="width:200px"></el-input>
        <el-button type="primary"
                   @click="getList"
                   style="margin-left:20px">查询</el-button>
      </div>
      <div class="list-box">
        <div class="flex align-center"
             v-for="(item,index) in list"
             :key="index">
          <el-checkbox v-model="item.checked"></el-checkbox>
          <img :src="item.picture_str"
               alt
               style="width:100px;height:100px" />
          <div>{{item.goods_name}}</div>
        </div>
      </div>
    </div>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="close"
                 plain>取 消</el-button>
      <el-button type="primary"
                 @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import discounts from '@/api/discounts';
export default {
  data() {
    return {
      goods_name: '',
      dialog: true,
      page: 1,
      total: 0,
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  props: {
    act_id: {
      type: [Number, String],
      default: 0
    }
  },
  methods: {
    // getGoodsList() {
    //   let params = {
    //     goods_name: this.goods_name,
    //     pageNumber: 1000
    //   };
    //   discounts.shopGoodsLists(params).then((res) => {
    //     let { code, data } = res;
    //     if (code === 0) {
    //       this.goodsData = data.list;
    //     }
    //   });
    // },
    getList() {
      let params = {
        act_id: this.act_id,
        goods_name: this.goods_name,
        pageNumber: 1000
      };
      discounts.addOrEditoGoodsLists(params).then((res) => {
        let { data } = res;
        let list = data.list;
        list.forEach((ele) => {
          ele.checked = false;
        });
        this.list = list;
      });
    },
    close() {
      this.$emit('close');
    },
    checkGood(item) {
      item.checked = !item.checked;
    },
    submit() {
      let list = this.list.filter((ele) => {
        return ele.checked;
      });
      this.$emit('checkGoods', list);
      this.close();
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  padding: 30px 30px 0;
  box-sizing: border-box;
}
.list-box {
  height: 400px;
  overflow: auto;
  margin-top: 15px;
  & > div {
    margin-bottom: 10px;
  }
  img {
    height: 70px;
    width: auto;
    margin: 0 15px;
  }
}
</style>
